html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 0;
  display: flex;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background 250ms ease-in-out, color 250ms ease-in-out;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.MuiDialog-root>.MuiBackdrop-root,
.kbar-positioner {
  backdrop-filter: blur(5px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div.MuiAlert-icon {
  align-items: center;
}

/* TODO: This is like... nasty! I know :-( */
div.MuiDataGrid-main div[style*='z-index: 100000; width: 100%;'] {
  display: none;
}

@keyframes pulse-circle {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-opacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}